<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
      <el-form-item label="名称" prop="title">
        <el-input v-model="info.title" placeholder="请输入名称"></el-input>
      </el-form-item>
      <el-form-item label="图片" prop="img">
        <SelectImgs :selectNum="1" :selectData="[info.img]" :selectFunc="imgChoose" :idx="1"></SelectImgs>
      </el-form-item>
      <el-form-item label="BANNER" prop="banner">
        <SelectImgs :selectNum="1" :selectData="[info.banner]" :selectFunc="imgChoose" :idx="2"></SelectImgs>
      </el-form-item>
      <el-form-item label="简介">
        <el-input type="textarea" rows="4" v-model="info.intro" placeholder="请输入简介"></el-input>
      </el-form-item>
      <el-form-item label="详情" prop="content">
        <wang-edit :txt="info.content" :callback="setText"></wang-edit>
      </el-form-item>
      <el-form-item label="排序">
        <el-input v-model="info.ordering" placeholder="请输入排序"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        <el-button type="button" @click="reBack()">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

import SelectImgs from '../../components/SelectImgs'
import WangEdit from "../../components/WangEdit";
export default {
  data() {
    return {
      tableHeader: [],
      tableData: [],
      info: {
        id: 0,
        img: '',
        intro: '',
        content: '',
      },
      source_domain: this.config.SOURCE_DOMAIN,
      rules: {
        title: [
          {required: true, message: '请输入名称', trigger: 'blur'}
        ],
      }
    };
  },
  created() {
    if (this.$route.query.id) {
      this.info.id = this.$route.query.id
      this.getInfo()
    }
  },
  mounted() {
  },
  components: {
    SelectImgs,
    WangEdit
  },
  computed: {},
  methods: {
    imgChoose(item,idx) {
      if(idx==1) {
        this.info.img = item.pic
      } else {
        this.info.banner = item.pic
      }
    },
    setText(txt) {
      this.info.content = txt
    },
    getInfo() {
      var that = this
      this.$api.course.courseTeacherDetail({id: that.info.id}, function (res) {
        if (res.errcode == 0) {
          that.info = res.data
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    submitForm(formName) {
      var that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var param = JSON.parse(JSON.stringify(this.info))
          this.$api.course.courseTeacherEdit(param, function (res) {
            if (res.errcode == 0) {
              that.success(res.errmsg)
              that.reBack()
            } else {
              that.fail(res.errmsg)
            }
          })
        } else {
          return false;
        }
      });
    },
  }
};
</script>
<style scoped>
.box {
  background-color: #fff;
  padding: 20px;
  border-radius: 6px;
  min-height: 500px;
}
</style>
